// plugins/axios.js

import axios from 'axios';

export default defineNuxtPlugin(nuxtApp => {
  const axiosInstance = axios.create({
    baseURL: 'https://shop.metgis.com/rest/V1',
  });

  axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('magentoToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  nuxtApp.provide('axios', axiosInstance);
});
