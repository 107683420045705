export default defineNuxtRouteMiddleware((to, from) => {
    if (!import.meta.server) {
        const isLoggedIn = useState('isLoggedIn').value;
        const isAdmin = useState("isAdmin");
        
        if (to.path === '/login') {
            return;
        }
        if (!isLoggedIn) {
            const router = useRouter();
            router.push('/login');
        }
        if (!isAdmin.value && to.path.startsWith('/admin')) {
            const router = useRouter();
            router.push('/login');            
        }
    }
})