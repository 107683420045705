import { default as addWarningoeoCFg5gdgMeta } from "/src/pages/addWarning.vue?macro=true";
import { default as _91customerid_93UjWcP3gKrgMeta } from "/src/pages/admin/[customerid].vue?macro=true";
import { default as indexwK3fSZ6B4uMeta } from "/src/pages/admin/index.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as loginTLBFe6DICLMeta } from "/src/pages/login.vue?macro=true";
import { default as overviewvz3PaISw2fMeta } from "/src/pages/overview.vue?macro=true";
import { default as register9mc1FDQpDSMeta } from "/src/pages/register.vue?macro=true";
export default [
  {
    name: "addWarning",
    path: "/addWarning",
    component: () => import("/src/pages/addWarning.vue")
  },
  {
    name: "admin-customerid",
    path: "/admin/:customerid()",
    component: () => import("/src/pages/admin/[customerid].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/src/pages/admin/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "overview",
    path: "/overview",
    component: () => import("/src/pages/overview.vue")
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/src/pages/register.vue")
  }
]